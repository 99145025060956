<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <CButton color="primary" @click="create()"
          ><CIcon name="cil-plus" /> Create New Customer</CButton
        >
        <br />
        <br />
        <br />

        <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>
        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page="50"
          hover
          pagination
        >
          <template #status="{ item }">
            <td>
              <CBadge :color="getBadge(item.status)">
                {{ item.status == 1 ? "Active" : "Blocked" }}
              </CBadge>
            </td>
          </template>
          <template #firstName="{ item }">
            <td>
              <strong>{{ item.firstName }}</strong>
            </td>
          </template>

          <template #name="{ item }">
            <td>
              <strong>{{ item.name }}</strong>
            </td>
          </template>

          <template #lastName="{ item }">
            <td>
              <strong>{{ item.lastName }}</strong>
            </td>
          </template>

          <template #email="{ item }">
            <td>
              <strong>{{ item.email }}</strong>
            </td>
          </template>

          <template #mobileNumber="{ item }">
            <td>
              <strong>{{ item.mobileNumber }}</strong>
            </td>
          </template>

          <template #Picture="{ item }">
            <td>
              <img v-if="item.picture"
                :src="item.picture"
                class="img-thumbnail"
                style="max-height: 50px"
              />
            </td>
          </template>

          <template #EDIT="{ item }">
            <td style="width: 15%">
              <CButton color="primary" @click="edit(item.id)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "Customers",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",

      // Delete
      customer: [],
      warningModal: false,

      //List
      items: [],
      fields: [
        "status",
        "firstName",
        "lastName",
        "email",
        "mobileNumber",
        "Picture",
        "EDIT",
      ],

      //Alert
      message: null,
      alertType: "danger",
    };
  },
  computed: {},
  methods: {
    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 2:
          return "danger";
        default:
          return "primary";
      }
    },
    edit(id) {
      const link = `customers/create/${id.toString()}`;
      this.$router.push({ path: link });
    },
    create() {
      this.$router.push({ path: `customers/create/${this.emptyGuid}` });
    },

    getList() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/customer`)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
